import React, { forwardRef, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import NextPage from '../../components/navigation/next/next-container';
import PreviousPage from '../../components/navigation/previous/previous-container';
import SEO from '../../components/seo/seo';

import useVisibleScrollBar from '../../utils/use-visible-scrollbar';

import './page-4.css';

const PageFour = forwardRef((props, ref) => (
  <>
    <SEO title="Page 4" />
    <main className="page page4">
      <div className="page__image-container">
        <StaticImage
          alt="Jimbugs shaking like a leaf"
          aspectRatio={2745 / 3816}
          className="page__image page__image-border"
          height={750}
          layout="constrained"
          loading="eager"
          placeholder="tracedSVG"
          src="../../images/page-4.png"
        />
      </div>
      <div
        className="page__text-container"
        ref={ref}
      >
        <div className="page__text">
          <p>
            Amanda began to pull back the sock, slowly revealing the strangest looking little
            creature she had ever seen.
          </p>
          <p>
            Standing in front of her, on top of the laundry, was a tiny man, about the size of
            a little bunny rabbit.
          </p>
          <p>
            He had very large, pointy ears and was wearing the most unusual clothing from head to foot.
          </p>
          <p>
            Now that Amanda could see him she wasn&apos;t afraid anymore.
          </p>
          <p>
            Jimbugs however, was shaking in his boots, which didn&apos;t really look like boots at all,
            except that they were on his feet!
          </p>
        </div>
      </div>
      <PreviousPage to="/page-3" />
      <NextPage to="/page-5" />
    </main>
  </>
));

const PageFourContainer = () => {
  const textRef = useRef();
  useVisibleScrollBar(textRef, 'page__text-container-scroll-indicator');

  return <PageFour ref={textRef} />;
};

export default PageFourContainer;
